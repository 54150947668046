import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import Toaster from "../../../common/Toaster";
import Input from "../../../input";
import InputWithIcon from "../../../input/inputWithIcon";
import LinkText from "../../../text/linkText";
import RoundedBtn from "../../../button/roundedBtn";
import { register, getStatesHandler } from "../../../../actions/user/action";
import { addNewBrand as addBrand } from "../../../../actions/brand/action";
import NavRight from "../../../button/navRight";
import NavLeft from "../../../button/navLeft";
import "./style.scss";
import { navigate } from "gatsby";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/customer-form-lottie.json";
import Select from "../../../select";
import allCities from "./cities";

const StepThree = ({
  assistant,
  register,
  addBrand,
  getStatesHandler,
  authenticated,
  data,
}) => {
  const [isMobile, setMobile] = useState(false);
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [renderFormik, setRenderFormik] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [formikValues, setFormikvalues] = useState({
    address: "",
    suite: "",
    city: "",
    state: "",
    zip: "",
  });

  useEffect(async () => {
    let states = await getStatesHandler();
    if (states.error === false && states.responseData.length > 0) {
      setStates(
        states.responseData.map((e) => {
          return { value: e.abbreviation, label: e.name };
        })
      );
    }

    const localstorageUser = JSON.parse(
      localStorage.getItem("registeringUser")
    );

    setFormikvalues({
      address:
        localstorageUser && localstorageUser.address
          ? localstorageUser.address
          : "",
      suite:
        localstorageUser && localstorageUser.suite
          ? localstorageUser.suite
          : "",
      city:
        localstorageUser && localstorageUser.city ? localstorageUser.city : "",
      state:
        localstorageUser && localstorageUser.state
          ? localstorageUser.state
          : "",
      zip: localstorageUser && localstorageUser.zip ? localstorageUser.zip : "",
    });

    if(localstorageUser && localstorageUser.state){

      let statesTemp =  states.responseData.map((e) => {
        return { value: e.abbreviation, label: e.name };
      })

      setCities(
        allCities[statesTemp.filter(function (option) {
          return option.value === localstorageUser.state;
        })[0].label].map((e) => {
          return { value: e, label: e };
        })
      );
    }

    setRenderFormik(true);

    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const setCitiesHandler = (state) => {
    setCities(
      allCities[state.label].map((e) => {
        return { value: e, label: e };
      })
    );
  };

  const navigateBack = () => {
    navigate("/onboarding/user-details");
  };

  return (
    <Fragment>
      <Toaster refresh={refresh} type="error" message={toastrMsg} />
      <div className="onboarding row user-details brand-location">
        <div className="top">
          <img src="/images/common/plus_new_logo.svg" />
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12 r-image">
          <div className="d-flex justify-content-center lottie-container">
            {!isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 200, height: 200, margin: "auto" }}
              />
            )}
            {isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 120, height: 120, margin: "auto" }}
              />
            )}
          </div>

          <div className="d-flex justify-content-center image-subtext">
            Let's start
            <br />
            <span>{`It takes > 1min.`}</span>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12  step-content">
          {renderFormik && (
            <Formik
              initialValues={{ ...formikValues }}
              enableReinitialze={true}
              validate={(values) => {
                const errors = {};

                if (!values.address) {
                  errors.address = "Required";
                }
                if (!values.city) {
                  errors.city = "Required";
                }
                if (!values.state) {
                  errors.state = "Required";
                }
                if (!values.zip) {
                  errors.zip = "Required";
                }
                return errors;
              }}
              onSubmit={async (values) => {
                if (!loading) {
                  setLoading(true);

                  var addBrandResonse = await addBrand({
                    address: values.address,
                    suite: values.suite,
                    city: values.city,
                    state: values.state,
                    zip: values.zip,
                  });

                  let localstorageUserState = JSON.parse(localStorage.getItem("registeringUser"));//{};
                  localstorageUserState.address = values.address;
                  localstorageUserState.suite = values.suite;
                  localstorageUserState.city = values.city;
                  localstorageUserState.state = values.state;
                  localstorageUserState.zip = values.zip;
                  localStorage.setItem(
                    "registeringUser",
                    JSON.stringify(localstorageUserState)
                  );
                  
                  if(addBrandResonse.responseData.shippingError && addBrandResonse.responseData.shippingError[0]){
                    setToastrMsg("Could not find this Address.");
                    doRefresh((prev) => prev + 1);
                    setLoading(false);
                  }
                  else if (addBrandResonse && !addBrandResonse.error) {
                    navigate("/onboarding/brand-age");
                  } 
                  else {
                    setToastrMsg("Unable to add Brand Location");
                    doRefresh((prev) => prev + 1);
                    setLoading(false);
                  }

                 
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                setFieldValue,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} className="form-register">
                  <>
                    <div className="form">
                      <div className="ip-group">
                        <Input
                          name="address"
                          placeholder="Address"
                          type="text"
                          value={values.address}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          icon="/images/common/user.svg"
                        />
                        {errors.address && touched.address && (
                          <p className="error-class">Please enter Address</p>
                        )}
                      </div>
                      <div className="row">
                        <div className="ip-group col-6">
                          <Input
                            placeholder="Suite# (if any)"
                            icon="/images/common/mail.svg"
                            type="text"
                            name="suite"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.suite}
                          />
                        </div>
                        <div className="ip-group col-6">
                          <Select
                            options={states}
                            placeholder="State"
                            defaultValue={states[0]}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("state", e.value);
                              setFieldValue("city", "");
                              setCitiesHandler(e);
                            }}
                            value={states.filter(function (option) {
                              return option.value === values.state;
                            })}
                          />
                          {errors.state && touched.state && (
                            <p className="error-class">
                              Please choose a State!
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="ip-group col-6">
                          <Select
                            options={cities}
                            placeholder="City"
                            defaultValue={cities[0]}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("city", e.value);
                            }}
                            value={cities.filter(function (option) {
                              return option.value === values.city;
                            })}
                          />
                          {errors.city && touched.city && (
                            <p className="error-class">Please choose a City!</p>
                          )}
                        </div>
                        <div className="ip-group col-6">
                          <Input
                            name="zip"
                            placeholder="Zip Code"
                            value={values.zip}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {errors.zip && touched.zip && (
                            <p className="error-class">
                              Please enter valid Zip Code!
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row back-buttons">
                      <div className="d-flex nav-icons">
                        <div className="margin-right-20-mobile">
                          <span
                            className="text-decoration-none"
                            onClick={() => navigateBack()}
                          >
                            <NavLeft type="button"/>
                            <span className="nav-text">Back</span>
                          </span>
                        </div>
                        <div>
                          <span
                            className="text-decoration-none"
                            onClick={() => handleSubmit()}
                          >
                            <span className="nav-text">Next</span>
                            <NavRight
                            type="submit"
                              blurred={
                                !values.name || !values.email || !values.phone
                                  ? 1
                                  : 0
                              }
                            />
                          </span>
                        </div>
                      </div>

                      {/* <div className="col-md-6 col-lg-6 col-sm-6 col-12"> </div>
                    <div className="col-md-6 col-lg-6 col-sm-6 col-12 step3-btn">
                      <RoundedBtn
                        background="#31FFEA"
                        color="#004E93"
                        tag="Continue"
                        type="submit"
                      />
                    </div> */}
                    </div>
                  </>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </Fragment>
  );
};
const BrandLocation = ({ data }) => ({
  assistant: data.assistant,
  authenticated: data.isAuthenticated,
  data: data,
});

export default connect(BrandLocation, { register, addBrand, getStatesHandler })(
  StepThree
);
